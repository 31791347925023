import { useQueryParameters } from "~/composables/useQueryParameters";
import { useCheckout } from "~/composables/useCheckout";
import { CheckoutSuccessStatus } from "~/types/checkoutSuccess";
import { useAwinTrackingClient } from "~/composables/api/tracking/useAwinTrackingClient";
import { useIterableTrackingClient } from "~/composables/api/tracking/useIterableTrackingClient";
import { useBiTrackingClient } from "~/composables";
import { useMetaTrackingClient } from "~/composables/api/tracking/useMetaTrackingClient";
import { type SetupIntentMetadata, StripePaymentMethod } from "~/types/stripe";
import type { UserType } from "~/stores/useUserStore";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return;

  const { $pinia, $i18n } = useNuxtApp();

  const authStore = useAuthStore($pinia);
  const userStore = useUserStore($pinia);
  const trackingStore = useTrackingStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);

  const { login } = authStore;
  const { register } = useRegistration();
  const { locale } = $i18n;
  const { trialDays } = useCheckout();
  const { createSubscription, createCustomer, getSetupIntent, extractPaymentMethodFromSetupIntent } =
    useStripeApiClient();
  const { checkoutSuccess } = storeToRefs(checkoutStore);
  const { user, userType } = storeToRefs(userStore);

  const queryParameters = useQueryParameters(to.query);
  const checkoutParameters = unref(useCheckout().checkoutParameters);

  if (
    !checkoutParameters ||
    !queryParameters.hasAll(["redirect_status", "setup_intent", "period", "currency", "sku", "amount"])
  ) {
    return;
  }

  const error = useError();

  if (queryParameters.get("redirect_status") === "failed" || error.value) {
    return navigateTo("/onboarding/checkout?payment_failed=true");
  }

  const setupIntent = await getSetupIntent(queryParameters.get("setup_intent"));
  const setupIntentMetaData = setupIntent.metadata as SetupIntentMetadata;

  if (!setupIntentMetaData?.country) {
    throw createError({ message: "Invalid setup intent: missing country" });
  }

  const paymentMethod = extractPaymentMethodFromSetupIntent(setupIntent);

  const customer_id = await (async () => {
    if (userType.value === "user" && user.value && user.value.stripe_customer_id) {
      return user.value.stripe_customer_id;
    }

    return (
      await createCustomer({
        setup_intent_metadata: setupIntentMetaData,
        payment_method: paymentMethod,
        email: checkoutParameters.email,
      })
    ).customer_id;
  })();

  if (userType.value === "guest") {
    await register();
    await login(checkoutParameters.email, checkoutParameters.password);
  }

  const transactionId = `${queryParameters.get("period")}_STRIPE_${uuidv4()}`;

  const segment = await discountStore.getActiveSegment();

  const subscription = await createSubscription({
    customer_id,
    sku: queryParameters.get("sku"),
    locale: locale.value,
    trial_days: trialDays.value,
    transaction_id: transactionId,
    coupon_code: discountStore.code,
    payment_method: paymentMethod,
    payment_method_types: (setupIntent.payment_method_types as StripePaymentMethod[]).filter(
      (method) => ![StripePaymentMethod.BANCONTACT, StripePaymentMethod.IDEAL].includes(method),
    ),
    country: setupIntentMetaData.country,
    setup_intent_metadata: setupIntentMetaData,
    segment,
  });

  checkoutSuccess.value = CheckoutSuccessStatus.PAID;
  checkoutStore.$persist();

  await useBiTrackingClient().trackPurchase({
    gateway: "stripe",
    period: queryParameters.get("period"),
    currency: queryParameters.get("currency"),
    sku: queryParameters.get("sku"),
    amountCustomerFacingCurrency: parseInt(queryParameters.get("amount")),
  });

  await useAwinTrackingClient().trackPurchase({
    currencyCode: subscription.currency,
    orderReference: transactionId,
    totalAmount: subscription.amount,
    voucherCode: discountStore.code,
  });

  await useIterableTrackingClient().trackPurchase({
    transactionId,
    email: checkoutParameters.email,
    planId: queryParameters.get("sku"),
    totalAmount: subscription.amount,
  });

  await useMetaTrackingClient().trackPurchase({
    transactionId,
    email: checkoutParameters.email,
    planId: queryParameters.get("sku"),
    totalAmount: subscription.amount,
    currency: subscription.currency,
  });

  trackingStore.addPurchaseEvent({
    transactionId,
    gateway: "stripe",
    period: subscription.period,
    currencyCode: subscription.currency,
    sku: queryParameters.get("sku"),
    totalAmount: subscription.amount,
    discountCode: discountStore.code,
    email: checkoutParameters.email,
    trial_days: trialDays.value,
  });

  trackingStore.$persist();

  const redirectRoutes: Record<UserType, string> = {
    guest: "/onboarding/finalize-account",
    user: "/onboarding/success",
    tokenUser: "/onboarding/success",
  };

  return navigateTo(redirectRoutes[userType.value]);
});
